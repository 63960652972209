import React, { Component, createContext } from "react";
import { auth, isAllowedUser } from "../db/firebase";

export const UserContext = createContext({ user: null });
export const LOGIN_STATUS = {
  INPROGRESS: 0,
  FAILED: 1,
  SUCCESSFULL: 2,
};
class UserProvider extends Component {
  state = {
    user: null,
    status: LOGIN_STATUS.INPROGRESS,
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(async (userAuth) => {
      const isAllowed = await isAllowedUser(userAuth?.uid);
      if (isAllowed) {
        this.setState({ user: userAuth, status: LOGIN_STATUS.SUCCESSFULL });
      } else {
        this.setState({ user: null, status: LOGIN_STATUS.FAILED });
      }
    });
  };
  render() {
    return <UserContext.Provider value={{ ...this.state }}>{this.props.children}</UserContext.Provider>;
  }
}
export default UserProvider;
