import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { Backup, Delete, FileCopy } from "@material-ui/icons";
import cx from "classnames";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { addImage, removeImage } from "../store/questionsSlice";
import { deleteFile, uploadFile } from "../db/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: 80,
    border: "1px solid",
    marginTop: 10,
    borderRadius: 4,
    width: "100%",
    "&:focus": {
      border: "2px dashed purple",
    },
  },
  highlight: {
    border: "2px dashed purple",
  },
  imageListContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.light,
    maxHeight: 280,
    transform: "translateZ(0)",
  },
  image: {
    maxWidth: 250,
    maxHeight: 250,
    objectFit: "scale-down",
    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  input: {
    display: "none",
  },
  media: {
    height: 140,
  },
  copyButton: {
    margin: theme.spacing(1),
    position: "relative",
    left: "70%",
    bottom: "5%",
    "&:hover": {
      color: "blue",
    },
  },
  deleteButton: {
    margin: theme.spacing(1),
    position: "relative",
    left: "65%",
    bottom: "5%",
    "&:hover": {
      color: "red",
    },
  },
}));
const preventDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
export default function SingleLineImageList({ questionSetId, images }) {
  const classes = useStyles();
  const [dragging, setDragging] = useState();
  const dispatch = useDispatch();
  const handleFileUpload = useCallback(
    async (file) => {
      const { url, fileName } = await uploadFile(file);
      dispatch(
        addImage({
          questionSetId,
          image: {
            url: url,
            fileName: fileName,
          },
        })
      );
    },
    [dispatch, questionSetId]
  );
  const onPaste = useCallback(
    (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (var index in items) {
        var item = items[index];
        if (item.kind === "file") {
          handleFileUpload(item.getAsFile());
        }
      }
    },
    [handleFileUpload]
  );

  const handleDragStart = useCallback(
    (e) => {
      preventDefaults(e);
      setDragging(true);
    },
    [setDragging]
  );
  const handleDragLeave = useCallback(
    (e) => {
      preventDefaults(e);
      setDragging(false);
    },
    [setDragging]
  );
  const handleDrop = useCallback(
    (e) => {
      handleDragLeave(e);
      const { files } = e.dataTransfer;
      Array.from(files).forEach(handleFileUpload);
    },
    [handleFileUpload, handleDragLeave]
  );

  const handleRemoveImage = useCallback(
    async (image) => {
      await deleteFile(image.fileName);
      dispatch(removeImage({ questionSetId, image }));
    },
    [dispatch, questionSetId]
  );
  const handlePasteUrl = useCallback((e, item) => {
    navigator.clipboard.writeText(item.url);
    e.preventDefault();
  }, []);
  return (
    <div
      tabIndex="-1"
      onPaste={onPaste}
      className={cx(classes.root, dragging ? classes.highlight : "")}
      onDragEnter={handleDragStart}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragStart}
      onDrop={handleDrop}
    >
      <div>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={(event) => {
            Array.from(event.target.files).forEach(handleFileUpload);
          }}
        />
        <label htmlFor="contained-button-file">
          <IconButton color="primary" aria-label="upload image" component="span" label="Upload Image">
            <Backup />
          </IconButton>
        </label>

        <Typography variant="caption">
          {images?.length} image(s). Drag and Drop, Paste or Click the upload button to add images. Click on Bin icon on
          image to remove them. Click on Copy icon to copy the image url.
        </Typography>
      </div>
      <div className={cx(classes.imageListContainer)}>
        {images?.map((item) => {
          return (
            <div key={item.url} className={classes.imageContainer}>
              <IconButton
                className={classes.copyButton}
                size="small"
                title="Copy Url"
                onClick={(e) => handlePasteUrl(e, item)}
              >
                <FileCopy fontSize="inherit" />
              </IconButton>
              <IconButton
                className={classes.deleteButton}
                size="small"
                title="Delete Image"
                onClick={() => handleRemoveImage(item)}
              >
                <Delete fontSize="inherit" />
              </IconButton>
              <img src={item.url} alt={item.title} className={classes.image} onClick={(e) => handlePasteUrl(e, item)} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
