import { Button, CircularProgress } from "@material-ui/core";
import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import { signInWithGoogle } from "./db/firebase";
import { LOGIN_STATUS, UserContext } from "./components/UserProvider";
export default function Main() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  const { user, status } = useContext(UserContext);
  if (user) {
    return <App />;
  }
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
        {!user && status === LOGIN_STATUS.FAILED && (
          <Button variant="outlined" color={"primary"} onClick={signInWithGoogle}>
            Login
          </Button>
        )}
        {!user && status === LOGIN_STATUS.INPROGRESS && <CircularProgress />}
      </div>
    </>
  );
}
