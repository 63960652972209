import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircleOutline, Delete, ExpandMore, Share } from "@material-ui/icons";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  addQuestion,
  deleteQuestion,
  deleteQuestionSet,
  save,
  setDescription,
  setTestTime,
  setTitle,
  setVisibility,
  updateChoice,
  updateQuestion,
} from "../store/questionsSlice";
import SingleLineImageList from "./ImageList";
import { QuestionEditor } from "./QuestionEditor";

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: 5,
  },
}));
function setClipboard(text) {
  var type = "text/plain";
  var blob = new Blob([text], { type });
  var data = [new window.ClipboardItem({ [type]: blob })];

  navigator.clipboard.write(data).then(
    function () {
      console.log("Url Copied");
    },
    function () {
      console.log("Url Copy failed");
    }
  );
}
export const Editor = ({ questionSetId, visible, title, testTime, description, questions, images }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleUpdate = useCallback(
    (id) => (key, value) => dispatch(updateQuestion({ questionSetId, questionId: id, key, value })),
    [dispatch, questionSetId]
  );

  const handleChoiceUpdate = useCallback(
    (id) => (e) =>
      dispatch(updateChoice({ questionSetId, questionId: id, choiceId: e.target.dataset.idx, value: e.target.value })),
    [dispatch, questionSetId]
  );
  const handleDeleteQuestion = useCallback(
    (id) => (e) => dispatch(deleteQuestion({ questionSetId, questionId: id })),
    [dispatch, questionSetId]
  );
  return (
    <form>
      <TextField
        size="small"
        label="Title"
        value={title}
        onChange={(e) => dispatch(setTitle({ questionSetId, value: e.target.value }))}
        className={classes.marginRight}
      />
      <TextField
        size="small"
        label="Test Time(Mins)"
        type="number"
        value={testTime}
        className={classes.marginRight}
        multiline
        InputProps={{
          inputProps: {
            min: 1,
          },
          type: "number",
        }}
        onChange={(e) => dispatch(setTestTime({ questionSetId, value: e.target.value }))}
      />
      <FormControlLabel
        size="small"
        control={
          <Switch
            margin="dense"
            checked={visible}
            onChange={(e) => dispatch(setVisibility({ questionSetId, isVisible: e.target.checked }))}
            name="visiblity"
            color="primary"
          />
        }
        label={visible ? "Visible to the users" : "Users cannot see this question set"}
      />
      <Button
        size="small"
        label="Save Changes"
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(save({ questionSetId }));
        }}
      >
        Save Changes
      </Button>
      <IconButton
        title="Copy question set Url"
        variant="contained"
        color="primary"
        onClick={() => setClipboard(`https://app.learningabode.in/#/questionset/${questionSetId}`)}
      >
        <Share />
      </IconButton>
      <IconButton
        className={classes.marginRight}
        variant="outlined"
        title="Delete QuestionSet"
        onClick={() => dispatch(deleteQuestionSet({ questionSetId }))}
      >
        <Delete />
      </IconButton>

      <TextField
        label="Description"
        size="small"
        fullWidth
        value={description}
        onChange={(e) => dispatch(setDescription({ questionSetId, value: e.target.value }))}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>{images?.length} image(s)</AccordionSummary>
        <AccordionDetails>
          <SingleLineImageList questionSetId={questionSetId} images={images} />
        </AccordionDetails>
      </Accordion>

      {questions?.map((question) => (
        <QuestionEditor
          key={question.id}
          {...question}
          handleUpdate={handleUpdate(question.id)}
          handleChoiceUpdate={handleChoiceUpdate(question.id)}
          handleDeleteQuestion={handleDeleteQuestion(question.id)}
        />
      ))}
      <IconButton title="Add Question" color="primary" onClick={() => dispatch(addQuestion({ questionSetId }))}>
        <AddCircleOutline />
      </IconButton>
    </form>
  );
};
