import { Grid, IconButton, makeStyles, Radio, TextField, Typography } from "@material-ui/core";
import { DeleteForeverOutlined } from "@material-ui/icons";
import { Fragment } from "react";
import { HTMLEditor } from "./HTMLEditor";
import { QuestionViewer } from "./QuestionViewer";
import { blue, red, teal } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  questions: {
    marginTop: 10,
    marginBottom: 2,
    padding: 2,
    paddingBottom: 5,
    background: teal[100],
    borderRadius: 5,
    border: "1px solid lightgrey",
  },
  answerSection: {
    marginTop: 5,
    padding: 2,
    display: "flex",
    alignContent: "flex-end",
  },
  textarea: {
    resize: "both",
  },
}));
export const QuestionEditor = ({
  id,
  title,
  choices,
  answer,
  handleUpdate,
  handleChoiceUpdate,
  handleDeleteQuestion,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.questions}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <HTMLEditor id={id} value={title} onChange={(e) => handleUpdate("title", e.target?.value)} />
          </Grid>
          <Grid item xs={6}>
            <QuestionViewer id={id} question={title} choices={choices} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {choices.map((choice, idx) => {
          return (
            <Fragment key={id + idx}>
              <Radio
                margin="dense"
                size="small"
                checked={!!choice && answer === choice}
                onChange={(e) => handleUpdate("answer", choice)}
                value={choice}
                name="radio-button-demo"
              />
              <TextField
                size="small"
                inputProps={{
                  "data-idx": idx,
                }}
                label={`Choice-${idx + 1}`}
                value={choice}
                onChange={handleChoiceUpdate}
              />
            </Fragment>
          );
        })}
      </Grid>
      <div className={classes.answerSection}>
        {!answer && (
          <div style={{ background: red[200], flexGrow: 1 }}>
            <Typography variant="caption">⚠️ Select the answer</Typography>
          </div>
        )}
        {answer && (
          <div style={{ background: blue[200], flexGrow: 1 }}>
            <Typography variant="caption">Answer: {answer}</Typography>
          </div>
        )}
        <IconButton title="Delete Question" size="small" onClick={handleDeleteQuestion}>
          <DeleteForeverOutlined color={"error"} />
        </IconButton>
      </div>
    </Grid>
  );
};
