import { configureStore } from "@reduxjs/toolkit";
import questionSetReducer from "./questionsSlice";
import categoriesReducer from "./categoriesSlice";
import notificationsSlice, { addNotification } from "./notificationsSlice";

const notificator = (store) => (next) => (action) => {
  let result = next(action);
  if (action.type === "questionSets/save") {
    store.dispatch(addNotification("Question set saved"));
  } else if (action.type === "categories/saveCategories") {
    store.dispatch(addNotification("All Categories saved"));
  }
  return result;
};

export default configureStore({
  reducer: {
    questionSets: questionSetReducer,
    categories: categoriesReducer,
    notifications: notificationsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(notificator),
});
