import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadQuestionBanks, saveQuestionSet } from "../db/firebase";
import { ID } from "../util";
const newQuestion = () => ({
  ...{ id: `${Date.now()}`, title: "", choices: ["", "", "", "", ""], answer: "" },
});
const newQuestionSet = (title, id) => ({
  ...{
    id,
    title,
    testTime: 5,
    description: "",
    questions: [newQuestion()],
    visible: false,
    deleted: false,
    images: [],
  },
});
const saveToServer = (questionSetId, questionSet) => {
  saveQuestionSet(questionSetId, {
    ...questionSet,
    testTime: !questionSet.testTime ? 0 : questionSet.testTime,
    questions: questionSet.questions.filter((q) => !!q.title),
  });
};
export const fetchQuestionSets = createAsyncThunk("fetchQuestionSets", async () => {
  const questionSets = await loadQuestionBanks();
  return questionSets;
});
export const questionSetSlice = createSlice({
  name: "questionSets",
  initialState: {},
  reducers: {
    addQuestionSet: (state) => {
      const id = ID();
      state[id] = newQuestionSet("New Set", id);
    },
    deleteQuestionSet: (state, { payload: { questionSetId } }) => {
      state[questionSetId].deleted = true;
    },
    setVisibility: (state, { payload: { questionSetId, isVisible } }) => {
      state[questionSetId].visible = isVisible;
    },
    setTitle: (state, { payload: { questionSetId, value } }) => {
      const questionSet = state[questionSetId];
      questionSet.title = value;
    },
    setTestTime: (state, { payload: { questionSetId, value } }) => {
      state[questionSetId].testTime = value ? parseInt(value) : value;
    },
    setDescription: (state, { payload: { questionSetId, value } }) => {
      state[questionSetId].description = value;
    },
    addQuestion: (state, { payload: { questionSetId } }) => {
      state[questionSetId].questions = [...state[questionSetId].questions, newQuestion()];
    },
    deleteQuestion: (state, { payload: { questionSetId, questionId } }) => {
      state[questionSetId].questions = [...state[questionSetId].questions.filter(({ id }) => id !== questionId)];
    },
    updateQuestion: (state, { payload: { questionSetId, questionId, key, value } }) => {
      const updatedQuestions = [...state[questionSetId].questions];
      const updatedQuestion = updatedQuestions.find((q) => q.id === questionId);
      updatedQuestion[key] = value;
      state[questionSetId].questions = updatedQuestions;
    },
    updateChoice: (state, { payload: { questionSetId, questionId, choiceId, value } }) => {
      const updatedQuestions = [...state[questionSetId].questions];
      const updatedQuestion = updatedQuestions.find((q) => q.id === questionId);
      updatedQuestion.choices[choiceId] = value;
      state[questionSetId].questions = updatedQuestions;
    },
    addImage: (state, { payload: { questionSetId, image } }) => {
      state[questionSetId].images = [...state[questionSetId].images, image];
      const questionSet = state[questionSetId];
      saveToServer(questionSetId, questionSet);
    },
    removeImage: (state, { payload: { questionSetId, image } }) => {
      state[questionSetId].images = [...state[questionSetId].images.filter(({ url }) => url !== image.url)];
      const questionSet = state[questionSetId];
      saveToServer(questionSetId, questionSet);
    },
    save: (state, { payload: { questionSetId } }) => {
      const questionSet = state[questionSetId];
      saveToServer(questionSetId, questionSet);
    },
  },
  extraReducers: {
    [fetchQuestionSets.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  initState,
  addQuestionSet,
  deleteQuestionSet,
  setVisibility,
  setTitle,
  setTestTime,
  setDescription,
  addQuestion,
  deleteQuestion,
  updateQuestion,
  updateChoice,
  addImage,
  removeImage,
  save,
} = questionSetSlice.actions;
export default questionSetSlice.reducer;
