import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIRichTextEditor from "mui-rte";
import { useCallback, useRef } from "react";

const defaultTheme = createTheme();

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: "100%",
      },
      editor: {
        borderBottom: "2px solid teal",
        minHeight: 50,
        Height: 100,
        overflowY: "scroll",
        resize: "vertical",
      },
      toolbar: {
        borderTop: "1px solid gray",
        backgroundColor: "#ebebeb",
      },
    },
  },
});
const CONTROLS = [
  "title",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "media",
  "numberList",
  "bulletList",
  "quote",
  "upload-image",
  "undo",
  "redo",
  //   "link",
  "clear",
  "save",
];
export const HTMLEditor = ({ id, value, onChange }) => {
  const ref = useRef(null);
  const onSave = useCallback(
    (data) => {
      onChange({ target: { value: data } });
      console.log(data);
    },
    [onChange]
  );
  const onBlur = useCallback(() => {
    if (ref.current) {
      ref.current.save();
    }
  }, [ref]);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <MuiThemeProvider theme={defaultTheme}>
        <MUIRichTextEditor
          id={id}
          ref={ref}
          toolbarButtonSize="small"
          controls={CONTROLS}
          value={value}
          inlineToolbar={true}
          label="Enter the question here... and Dont forget to click on Save after each change"
          onSave={onSave}
          onBlur={onBlur}
        />
      </MuiThemeProvider>
    </div>
  );
};
