import { IconButton, Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../store/notificationsSlice";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useRef } from "react";
export const Notifications = () => {
  const ref = useRef();
  const notifications = useSelector((state) => state.notifications || []);
  const dispatch = useDispatch();
  const handleClose = useCallback((id) => () => dispatch(removeNotification(id)), [dispatch]);
  return (
    <div>
      {notifications.map(({ id, message }) => {
        return (
          <Snackbar
            ref={ref}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            key={id}
            open={true}
            autoHideDuration={5000}
            onClose={handleClose(id)}
            message={message}
            action={
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose(IDBCursorWithValue)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />
        );
      })}
    </div>
  );
};
