import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  addCategory,
  changeCategoryName,
  addSubCategory,
  changeSubCategoryName,
  setQuestionSets,
  removeCategory,
  removeSubCatagory,
  saveCategories,
} from "../../store/categoriesSlice";
import { useState, useMemo, useCallback, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { NameChangeDialog } from "./NameChangeDialog";
import { QuestionSetSelector } from "./QuestionSetSelector";
const useStyle = makeStyles(() => ({
  grid: {
    border: "1px solid lightgrey",
    borderLeft: 0,
    height: "90%",
  },
  list: {
    height: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  title: {
    padding: 5,
  },
}));
export const CategoryManager = () => {
  const categories = useSelector((state) => state.categories);
  const questionSets = useSelector((state) => Object.values(state.questionSets));
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState();
  const [nameChangeConfig, setNameChangeConfig] = useState({ open: false });
  const [questionSetChangeConfig, setQuestionSetChangeConfig] = useState({ open: false });
  const classes = useStyle();
  const dispatch = useDispatch();
  const selectedQuestionSet = useMemo(() => {
    return (
      selectedCategoryId &&
      selectedSubCategoryId &&
      categories[selectedCategoryId]?.subCategories
        .find((sc) => sc.id === selectedSubCategoryId)
        ?.questionSets.map((qsid) => questionSets.filter(({ deleted }) => !deleted).find((qs) => qs.id === qsid))
        .filter((i) => !!i)
    );
  }, [categories, questionSets, selectedCategoryId, selectedSubCategoryId]);
  const handleNameChange = useCallback(
    (newName) => {
      if (nameChangeConfig.type === "category") {
        dispatch(changeCategoryName({ categoryId: nameChangeConfig.categoryId, name: newName }));
      } else if (nameChangeConfig.type === "subCategory") {
        dispatch(
          changeSubCategoryName({
            categoryId: selectedCategoryId,
            subCategoryId: selectedSubCategoryId,
            name: newName,
          })
        );
      }
      setNameChangeConfig({ open: false });
    },
    [dispatch, nameChangeConfig.categoryId, nameChangeConfig.type, selectedCategoryId, selectedSubCategoryId]
  );
  const handleQuestionSetsChange = useCallback(
    (newSet) => {
      dispatch(
        setQuestionSets({
          categoryId: selectedCategoryId,
          subCategoryId: selectedSubCategoryId,
          questionSets: newSet,
        })
      );
      setQuestionSetChangeConfig({ open: false });
    },
    [dispatch, selectedCategoryId, selectedSubCategoryId]
  );
  useEffect(() => {
    if (categories && Object.values(categories).length && !selectedCategoryId) {
      const catId = Object.values(categories).filter(({ deleted }) => !deleted)[0]?.id;
      if (catId) {
        setSelectedCategoryId(catId);
        setSelectedSubCategoryId(categories[catId].subCategories[0].id);
      }
    }
  }, [categories, selectedCategoryId]);
  return (
    <Paper>
      <div className={classes.title}>
        <Grid container alignItems="stretch">
          <Grid item xs={10}>
            <Typography variant={"h6"}>Manage Categories</Typography>
          </Grid>
          <Grid item>
            <Button size="small" variant="contained" color={"secondary"} onClick={() => dispatch(saveCategories())}>
              Save Categories
            </Button>
          </Grid>
        </Grid>
      </div>
      <NameChangeDialog open={nameChangeConfig.open} currentName={nameChangeConfig.name} onClose={handleNameChange} />
      <QuestionSetSelector
        open={questionSetChangeConfig.open}
        availableQuestionSets={questionSets}
        currentQuestionSets={questionSetChangeConfig.questionSets}
        handleClose={handleQuestionSetsChange}
      />
      <Grid container>
        <Grid item xs={3} className={classes.grid}>
          <ListItem alignItems="center" button onClick={() => dispatch(addCategory())} color={"blue"}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New Catergory" />
          </ListItem>
          <div className={classes.list}>
            <List>
              {categories &&
                Object.values(categories)
                  .filter(({ deleted }) => !deleted)
                  .map((cat) => {
                    return (
                      <ListItem
                        selected={cat.id === selectedCategoryId}
                        button
                        key={cat.id}
                        onClick={() => {
                          if (cat.id !== selectedCategoryId && cat.id) {
                            setSelectedSubCategoryId(categories[cat.id].subCategories[0].id);
                          }
                          setSelectedCategoryId(cat.id);
                        }}
                        onDoubleClick={() => {
                          setNameChangeConfig({ open: true, name: cat.name, type: "category", categoryId: cat.id });
                        }}
                        title="Double click to change the name"
                      >
                        <ListItemText primaryTypographyProps={{ variant: "subtitle2" }} primary={cat.name} />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => dispatch(removeCategory({ categoryId: cat.id }))}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
            </List>
          </div>
        </Grid>
        <Grid item xs={3} className={classes.grid}>
          <ListItem
            button
            alignItems="center"
            disabled={!selectedCategoryId}
            onClick={() => dispatch(addSubCategory({ categoryId: selectedCategoryId }))}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New SubCatergory" />
          </ListItem>
          <div className={classes.list}>
            <List>
              {selectedCategoryId &&
                categories[selectedCategoryId].subCategories.map((cat) => {
                  return (
                    <ListItem
                      selected={cat.id === selectedSubCategoryId}
                      button
                      key={cat.id}
                      onClick={() => setSelectedSubCategoryId(cat.id)}
                      onDoubleClick={() => {
                        setNameChangeConfig({
                          open: true,
                          name: cat.name,
                          type: "subCategory",
                        });
                      }}
                      title="Double click to change the name"
                    >
                      <ListItemText primaryTypographyProps={{ variant: "subtitle2" }} primary={cat.name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            dispatch(removeSubCatagory({ subCategoryId: cat.id, categoryId: selectedCategoryId }))
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Grid>

        <Grid item xs={6} className={classes.grid}>
          <ListItem
            button
            alignItems="center"
            disabled={!selectedSubCategoryId}
            onClick={() => {
              setQuestionSetChangeConfig({
                open: true,
                questionSets: selectedQuestionSet.map(({ id }) => id),
              });
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Question Sets" />
          </ListItem>
          <div className={classes.list}>
            <List>
              {selectedQuestionSet &&
                selectedQuestionSet.map(({ id, title, description }) => {
                  return (
                    <ListItem divider key={id}>
                      <ListItemText primaryTypographyProps={{ variant: "subtitle2" }} primary={title}></ListItemText>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
