import { createSlice } from "@reduxjs/toolkit";
import { ID } from "../util";
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    addNotification: (state, { payload }) => {
      state.push({ id: ID(), message: payload });
    },
    removeNotification: (state, { payload }) => {
      const ideToDel = state.findIndex((s) => s.id === payload);
      state.splice(ideToDel, 1);
    },
  },
});

export const { addNotification, removeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
