import { Paper, Radio } from "@material-ui/core";
import { Fragment } from "react";
import { toHtml } from "../util";
export const QuestionViewer = ({ id, question, choices }) => {
  return (
    <Paper style={{ marginTop: 10, marginLeft: 5, padding: 5 }}>
      <div
        style={{ height: "70%" }}
        dangerouslySetInnerHTML={{ __html: question ? toHtml(question) : "Save the question to view it here" }}
      ></div>
      {choices.filter((c) => !!c).length ? (
        <div>
          Choose the correct answer:
          {choices
            .filter((c) => !!c)
            .map((choice, idx) => {
              return (
                <Fragment key={id + idx}>
                  <Radio margin="dense" size="small" defaultValue={choice} name="radio-button-answer" />
                  {choice}
                </Fragment>
              );
            })}
        </div>
      ) : (
        <></>
      )}
    </Paper>
  );
};
