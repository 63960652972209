import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Select } from "@material-ui/core";
import { useState, useEffect, useCallback } from "react";

export const QuestionSetSelector = ({ open, availableQuestionSets = [], currentQuestionSets, handleClose }) => {
  const [questionSets, setQuestionSets] = useState(currentQuestionSets);
  useEffect(() => setQuestionSets(currentQuestionSets), [currentQuestionSets]);
  const handleChangeMultiple = useCallback((event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setQuestionSets(value);
  }, []);
  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(questionSets)}>
        <DialogTitle id="form-dialog-title">Available Question Sets</DialogTitle>
        <DialogContent>
          <Select multiple native value={questionSets} onChange={handleChangeMultiple}>
            {availableQuestionSets.map(({ title, id }, i) => (
              <option key={id + i} value={id}>
                {title}
              </option>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(questionSets)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(questionSets)} color="primary">
            Save({questionSets?.length})
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
