import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./Main";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@material-ui/core";
import { Provider } from "react-redux";
import store from "./store/store";
import UserProvider from "./components/UserProvider";
import { fetchQuestionSets } from "./store/questionsSlice";
import { fetchCategories } from "./store/categoriesSlice";

store.dispatch(fetchQuestionSets());
store.dispatch(fetchCategories());

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <Provider store={store}>
        <CssBaseline />
        <Main />
      </Provider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
