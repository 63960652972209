import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadCategories, saveCategory } from "../db/firebase";
import { ID } from "../util";
const newSubCatId = () => `${Date.now()}`;
const newSubCategory = (name) => ({
  id: newSubCatId(),
  name,
  questionSets: [],
});
const newCatgory = (name, id) => ({
  id,
  name,
  subCategories: [newSubCategory("New SubCategory")],
  deleted: false,
  visible: true,
});
export const fetchCategories = createAsyncThunk("fetchCategories", async () => {
  return await loadCategories();
});

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {},
  reducers: {
    addCategory: (state) => {
      const id = ID();
      state[id] = newCatgory("New Category", id);
    },
    changeCategoryName: (state, { payload: { categoryId, name } }) => {
      const category = state[categoryId];
      category.name = name;
    },
    removeCategory: (state, { payload: { categoryId } }) => {
      state[categoryId].deleted = true;
    },
    addSubCategory: (state, { payload: { categoryId } }) => {
      const category = state[categoryId];
      category.subCategories = [...category.subCategories, newSubCategory("New SubCategory")];
    },
    changeSubCategoryName: (state, { payload: { categoryId, subCategoryId, name } }) => {
      const category = state[categoryId];
      category.subCategories = [
        ...category.subCategories.map((sc) => {
          if (sc.id === subCategoryId) {
            return { ...sc, name: name };
          }
          return { ...sc };
        }),
      ];
    },
    removeSubCatagory: (state, { payload: { categoryId, subCategoryId } }) => {
      const category = state[categoryId];
      category.subCategories = [...category.subCategories.filter((sc) => sc.id !== subCategoryId)];
    },
    setQuestionSets: (state, { payload: { categoryId, subCategoryId, questionSets } }) => {
      const category = state[categoryId];
      category.subCategories = [
        ...category.subCategories.map((sc) => {
          if (sc.id === subCategoryId) {
            return { ...sc, questionSets: [...questionSets] };
          }
          return { ...sc };
        }),
      ];
    },
    removeQuestionSet: (state, { payload: { categoryId, subCategoryId, questionSetId } }) => {
      const category = state[categoryId];
      category.subCategories = [
        ...category.subCategories.map((sc) => {
          if (sc.id === subCategoryId) {
            return { ...sc, questionSets: [...sc.questionSets.filter((qs) => qs !== questionSetId)] };
          }
          return { ...sc };
        }),
      ];
    },
    saveCategories: (state) => {
      for (let category of Object.values(state)) {
        const cat = JSON.parse(JSON.stringify(category));
        saveCategory(cat);
      }
    },
  },
  extraReducers: {
    [fetchCategories.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  addCategory,
  changeCategoryName,
  removeCategory,
  addSubCategory,
  removeSubCatagory,
  setQuestionSets,
  removeQuestionSet,
  changeSubCategoryName,
  saveCategories,
} = categoriesSlice.actions;
export default categoriesSlice.reducer;
