import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExitToApp } from "@material-ui/icons";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { CategoryManager } from "./components/category-manager/CategoryManager";
import { Notifications } from "./components/Notifications";
import { Editor } from "./components/QuestionSetEditor";
import { signOut } from "./db/firebase";
import { addQuestionSet } from "./store/questionsSlice";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
  },
  marginRight: {
    marginRight: 5,
  },
  selectedItem: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      background: theme.palette.primary.light,
      // color: theme.palette.secondary.light,
    },
  },
}));
function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const questionSets = useSelector((state) => state.questionSets);
  const [selectedQuestionSetId, setSelectedQuestionSetId] = useState("ManageCategories");
  const sortedQuesntionSets = useMemo(
    () =>
      Object.values(questionSets || {})
        ?.filter(({ deleted }) => !deleted)
        .sort((a, b) => a.title.localeCompare(b.title)),
    [questionSets]
  );
  if (!questionSets)
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
        <CircularProgress color="secondary" />
        <div style={{ margingLeft: 10, padding: 10 }}>Loading data from the server</div>
      </div>
    );

  return (
    <div className={classes.root}>
      <Notifications />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" className={classes.marginRight}>
                Learning Abode Question Bank - Admin
              </Typography>
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" color={"secondary"} onClick={() => dispatch(addQuestionSet())}>
                New Question Set
              </Button>
              <IconButton label="LogOut" size="small" color={"secondary"} onClick={signOut}>
                <ExitToApp />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem
              button
              onClick={() => setSelectedQuestionSetId("ManageCategories")}
              className={"ManageCategories" === selectedQuestionSetId ? classes.selectedItem : ""}
            >
              <ListItemText color="primary" primary={"Categories"} />
            </ListItem>
            {sortedQuesntionSets.map(({ id: qsid, title }) => {
              return (
                <ListItem
                  button
                  key={qsid}
                  onClick={() => setSelectedQuestionSetId(qsid)}
                  className={qsid === selectedQuestionSetId ? classes.selectedItem : ""}
                >
                  <ListItemText color="primary" primary={title} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Container>
          <Box my={2}>
            {selectedQuestionSetId === "ManageCategories" && <CategoryManager />}
            {selectedQuestionSetId && selectedQuestionSetId !== "ManageCategories" && (
              <Editor questionSetId={selectedQuestionSetId} {...questionSets[selectedQuestionSetId]} />
            )}
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default App;
