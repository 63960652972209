import { ID } from "../util";
//import store from "../store/store";
//import { addNotification } from "../store/notificationsSlice";
const firebase = window.firebase ?? {};

export const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();
if (window.location.hostname === "localhost") {
  console.log("Dev build detected, using Emulator");
  auth.useEmulator("http://localhost:9099");
  db.useEmulator("localhost", 8080);
  storage.useEmulator("localhost", 9199);
}
const provider = new firebase.auth.GoogleAuthProvider();

export const loadQuestionBanks = async () => {
  console.log("Loading db");
  const qbankRef = db.collection("question-bank");
  const allData = {};
  let count = 0;
  (await qbankRef.get()).forEach((doc) => {
    allData[doc.id] = doc.data();
    count++;
    //allData.push({ doc.id:{...doc.data()}});
  });
  console.log(`Loaded ${count} question sets`);
  return allData;
};

export const saveQuestionSet = async (id, questionSet) => {
  const qbankRef = db.collection("question-bank");
  qbankRef
    .doc(id)
    .set(questionSet)
    .then(() => {
      console.log(`QuestionSet ${id} saved`);
      // store.dispatch(addNotification("Question Set Saved"));
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

export const saveCategory = async (category) => {
  const qbankRef = db.collection("question-category");
  qbankRef
    .doc(category.id)
    .set(category)
    .then(() => {
      console.log(`Category ${category.id} saved`);
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};
export const loadCategories = async () => {
  console.log("Loading db");
  const qCateRef = db.collection("question-category");
  const allData = {};
  let count = 0;
  (await qCateRef.get()).forEach((doc) => {
    allData[doc.id] = doc.data();
    count++;
    //allData.push({ doc.id:{...doc.data()}});
  });
  console.log(`Loaded ${count} question categories`);
  return allData;
};
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};
export const signOut = () => {
  auth.signOut();
};

export const isAllowedUser = async (id) => {
  if (!id) return false;
  console.log("Checking ", id);
  const usersRef = db.collection("Users");
  return usersRef
    .where("id", "==", id)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.size > 0;
    })
    .catch((e) => {
      console.log(e);
    });
};

// Create a root reference
const storageRef = storage.ref();
export const deleteFile = (fileName) => {
  var deleteRef = storageRef.child(fileName);
  return new Promise((resolve, reject) => {
    // Delete the file
    deleteRef
      .delete()
      .then(() => {
        console.log("Image Deleted");
        resolve();
      })
      .catch((error) => {
        console.error("Error while Deleting image ", error);
        reject(error);
      });
  });
};
export const uploadFile = (file) => {
  const fileName = !file.name || file.name === "image.png" ? ID() + ".png" : file.name;
  const imgRef = storageRef.child(fileName);
  return new Promise((resolve, reject) => {
    const uploadTask = imgRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        console.error("Error while Uploading image ", error);
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          resolve({ fileName, url: downloadURL });
        });
      }
    );
  });
};
