import { convertToHTML } from "draft-convert";
import { convertFromRaw } from "draft-js";

export const toHtml = (rawContentState) => {
  if (!rawContentState) return rawContentState;
  const contentState = convertFromRaw(JSON.parse(rawContentState));
  return convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      if (entity.type === "IMAGE") {
        return (
          <img
            src={entity.data.url}
            alt={originalText}
            height={entity.data.height}
            width={entity.data.width}
            // align={entity.data.alignment || "left"}
          />
        );
      }
      return originalText;
    },
  })(contentState);
};

export const ID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};
