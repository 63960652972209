import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useState, useEffect, forwardRef, useRef, useCallback } from "react";

export const NameChangeDialog = forwardRef(({ currentName, open, onClose }, ref) => {
  const refO = useRef(null);
  const [name, setName] = useState(currentName);
  useEffect(() => setName(currentName), [currentName]);
  const handleClose = useCallback(
    (value) => {
      onClose(value);
      setName("");
    },
    [onClose]
  );
  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(currentName)} ref={!ref ? refO : ref}>
        <DialogTitle id="form-dialog-title">Change Name</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a new name here</DialogContentText>
          {name && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(currentName)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(name)} color="primary">
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
